import FlagIcon from '@mui/icons-material/Flag';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import IconButton from '@mui/material/IconButton';
import { Asset, Utterance } from 'shared-models';
import {
  ButtonContainer,
  CardHeader,
  CheckboxContainer,
  DateAdded,
  DownloadButton,
  FlagButton,
  RatingContainer,
  RatingSum,
  StyledVideo,
  SubmitButton,
  Table,
  VideoCardDiv,
} from '../styles/VideoList.style';

import { Box, Checkbox, Chip, Tab, Tabs, Tooltip } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { API_URL } from 'src/utils/api';
import styled from 'styled-components';
import { AssetEditorState, CheckedUtterancesType, ScoreData } from './VideoList';

const formatDate = (seconds: number): string => {
  const date = new Date(seconds * 1000); // Convert seconds to milliseconds
  return date.toLocaleString(); // This will use the user's local timezone
};

/** Human annotations with these names will be hidden. */
const HIDDEN_HUMAN_ANNOTATIONS = new Set(['logger']);



export type VideoCardProps = {
  asset: Asset;
  scoreData: ScoreData | null;
  handleFlagContent: (assetId: string) => Promise<void>;
  handleThumbsUp: (assetId: string) => Promise<void>;
  handleThumbsDown: (assetId: string) => Promise<void>;
  handleSubmitClick: (state: AssetEditorState) => void | Promise<void>;
  userRating: number;
  assetRating: number;
  searchTerm: string;
};

const VideoCard: React.FC<VideoCardProps> = ({
  asset,
  scoreData,
  handleFlagContent,
  handleThumbsUp,
  handleThumbsDown,
  userRating,
  assetRating,
  handleSubmitClick,
  searchTerm,
}) => {
  const [checkedUtterances, setCheckedUtterances] = useState<CheckedUtterancesType>([]);

  const handleCheckboxChange = (utteranceIndex: number) => {
    setCheckedUtterances((prev) => {
      const updatedAssetUtterances = [...prev];
      updatedAssetUtterances[utteranceIndex] = !updatedAssetUtterances[utteranceIndex];
      return updatedAssetUtterances;
    });
  };

 const highlightText = (text: string, highlight: string) => {
    if (!highlight.trim()) {
      return <span>{text}</span>;
    }
    const regex = new RegExp(`(${highlight})`, 'gi');
    const parts = text.split(regex);
    return (
      <span>
        {parts.filter(String).map((part, i) =>
          regex.test(part) ? <mark key={i}>{part}</mark> : <span key={i}>{part}</span>
        )}
      </span>
    );
  };


  const hasHumanAnnotations = useMemo(() => {
  return !!asset.human_annotations && Object.keys(asset.human_annotations).length > 0;
  }, [asset.human_annotations]);

 const hasScores = useMemo(() => {
    const result = scoreData !== null && scoreData.scores && Object.keys(scoreData.scores).length > 0;
    console.log('hasScores:', result, 'scoreData:', scoreData);
    return result;
  }, [scoreData]);

  useEffect(() => {
    console.log('VideoCard rendered for asset:', asset.id);
    console.log('scoreData:', scoreData);
    console.log('hasScores:', hasScores);
  }, [asset.id, scoreData, hasScores]);

  return (
    <VideoCardDiv>
      <StyledVideo
        hlsUrl={`${API_URL}/video/assets/${encodeURIComponent(asset.id)}/${encodeURIComponent(
          asset.id,
        )}.m3u8`}
        nativeUrl={`${API_URL}/video/assets/${encodeURIComponent(asset.id)}`}
      />
      <CardHeader>
        <DateAdded>{formatDate(asset.date_added)}</DateAdded>
        <Tooltip title="Flag Content for Review" arrow>
          <FlagButton
            onClick={() => handleFlagContent(asset.id)}
            aria-label="flag content for review"
            size="small"
          >
            <FlagIcon />
          </FlagButton>
        </Tooltip>
        <RatingContainer>
          <IconButton
            onClick={() => handleThumbsUp(asset.id)}
            aria-label="thumbs up"
            color={userRating === 1 ? 'primary' : 'default'}
          >
            <ThumbUpIcon />
          </IconButton>
          <RatingSum>{assetRating || 0}</RatingSum>
          <IconButton
            onClick={() => handleThumbsDown(asset.id)}
            aria-label="thumbs down"
            color={userRating === -1 ? 'error' : 'default'}
          >
            <ThumbDownIcon />
          </IconButton>
        </RatingContainer>
      </CardHeader>
      <TabsContainer
        asset={asset}
        scoreData={scoreData}
        checkedUtterances={checkedUtterances}
        handleCheckboxChange={handleCheckboxChange}
        renderChips={renderChips}
        searchTerm={searchTerm}
        highlightText={highlightText}
        hasHumanAnnotations={hasHumanAnnotations}
        hasScores={hasScores}
      />
      <ButtonContainer>
        <DownloadButton
          href={`${API_URL}/video/assets/${encodeURIComponent(asset.id)}`}
          download
          target="_blank"
          rel="noopener noreferrer"
        >
          Download Video
        </DownloadButton>
        <SubmitButton onClick={() => handleSubmitClick({ id: asset.id, checkedUtterances })}>
          Submit for Editing
        </SubmitButton>
      </ButtonContainer>
    </VideoCardDiv>
  );
};

const getChipColor = (content: string) => {
  let hash = 0;
  for (let i = 0; i < content.length; i++) {
    hash = content.charCodeAt(i) + ((hash << 5) - hash);
  }
  const hue = hash % 360;
  return `hsl(${hue}, 70%, 80%)`;
};

const renderChips = (items: string[]) => {
  return items.map((item, index) => (
    <Chip key={index} label={item} style={{ backgroundColor: getChipColor(item), margin: '2px' }} />
  ));
};

interface CustomTabProps {
  label: string;
  hasMatch: boolean;
  value: number;
  id: string;
  'aria-controls': string;
}

const StyledTab = styled(Tab)<{ $hasMatch: boolean }>`
  ${props => props.$hasMatch && `
    font-weight: bold;
    color: #f50057 !important; // Use !important to override Material-UI styles
    &::after {
      content: ' 🔍';
    }
  `}
`;

const CustomTab: React.FC<CustomTabProps> = ({ hasMatch, ...props }) => {
  return <StyledTab $hasMatch={hasMatch} {...props} />;
};

const TabsContainer: React.FC<{
  asset: Asset;
  scoreData: ScoreData | null;
  checkedUtterances: CheckedUtterancesType;
  handleCheckboxChange: (utteranceIndex: number) => void;
  renderChips: (items: string[]) => React.ReactNode;
  searchTerm: string;
  highlightText: (text: string, highlight: string) => React.ReactNode;
  hasHumanAnnotations: boolean;
  hasScores: boolean;
}> = ({ asset, scoreData, checkedUtterances, handleCheckboxChange, renderChips, searchTerm, highlightText, hasHumanAnnotations, hasScores }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const hasMatchInAnnotations = useMemo(() => {
    if (!searchTerm || !asset.annotations) return false;
    const searchRegex = new RegExp(searchTerm, 'i');
    return Boolean(
      searchRegex.test(asset.annotations.description) ||
      asset.annotations.topics?.some(topic => searchRegex.test(topic)) ||
      Object.values(asset.annotations.speakers ?? {}).some(speaker => searchRegex.test(speaker)) ||
      asset.annotations.vibes?.some(vibe => searchRegex.test(vibe))
    );
  }, [asset.annotations, searchTerm]);


  const hasMatchInHumanAnnotations = useMemo(() => {
    if (!searchTerm || !asset.human_annotations) return false;
    const searchRegex = new RegExp(searchTerm, 'i');
    return Object.entries(asset.human_annotations).some(([key, value]) =>
      searchRegex.test(key) || searchRegex.test(String(value))
    );
  }, [asset.human_annotations, searchTerm]);

  const hasMatchInTranscription = useMemo(() => {
    if (!searchTerm || !asset.transcription) return false;
    const searchRegex = new RegExp(searchTerm, 'i');
    return asset.transcription.some(utterance => searchRegex.test(utterance.text));
  }, [asset.transcription, searchTerm]);

   const hasMatchInScores = useMemo(() => {
    if (!searchTerm || !scoreData) return false;
    const searchRegex = new RegExp(searchTerm, 'i');
    return Object.keys(scoreData.scores).some(key => searchRegex.test(key));
  }, [scoreData, searchTerm]);


  return (
    <>
       <Tabs value={selectedTab} onChange={handleChange}>
        <CustomTab
          label="Machine Annotations"
          hasMatch={hasMatchInAnnotations}
          value={0}
          id={`tab-${asset.id}-0`}
          aria-controls={`tabpanel-${asset.id}-0`}
        />
        {hasHumanAnnotations && (
          <CustomTab
            label="Human Annotations"
            hasMatch={hasMatchInHumanAnnotations}
            value={1}
            id={`tab-${asset.id}-1`}
            aria-controls={`tabpanel-${asset.id}-1`}
          />
        )}
        <CustomTab
          label="Transcription"
          hasMatch={hasMatchInTranscription}
          value={2}
          id={`tab-${asset.id}-2`}
          aria-controls={`tabpanel-${asset.id}-2`}
        />
      </Tabs>
      <TabPanel value={selectedTab} index={0}>
        {asset.annotations && (
          <Table>
            <tbody>
              <tr>
                <td>Description</td>
                <td>{highlightText(asset.annotations.description, searchTerm)}</td>
              </tr>
              {asset.annotations.topics && (
                <tr>
                  <td>Topics</td>
                  <td>{renderChips(asset.annotations.topics)}</td>
                </tr>
              )}
              {asset.annotations?.speakers && (
                <tr>
                  <td>Speakers</td>
                  <td>{renderChips(asset.annotations?.speakers ? Object.values(asset.annotations.speakers) : [])}</td>
                </tr>
              )}
              {asset.annotations.vibes && (
                <tr>
                  <td>Vibes</td>
                  <td>{renderChips(asset.annotations.vibes)}</td>
                </tr>
              )}
              {hasScores && scoreData && (
                <tr>
                  <td>Blue Rose Score</td>
                  <td className="py-2 px-4">{scoreData.percentiles["everyone"]} / 100</td>
                </tr>
              )}
            </tbody>
          </Table>
        )}
      </TabPanel>

      <TabPanel value={selectedTab} index={1}>
        {asset.human_annotations && (
          <Table>
            <tbody>
              {Object.entries(asset.human_annotations)
                .filter(([key]) => !HIDDEN_HUMAN_ANNOTATIONS.has(key))
                .map(([key, value]) => (
                  <tr key={key}>
                    <td>{key}</td>
                    <td>{formatValue(value)}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        )}
      </TabPanel>

      <TabPanel value={selectedTab} index={2}>
        {asset.transcription && asset.transcription.length > 0 ? (
          <Table>
            <thead>
              <tr>
                <th>Select</th>
                <th>Start Time</th>
                <th>End Time</th>
                <th>Text</th>
                {asset.transcription.some((u) => u.speaker) && <th>Speaker</th>}
              </tr>
            </thead>
             <tbody>
              {asset.transcription.map((utterance: Utterance, idx: number) => {
                const isHighlighted = searchTerm && utterance.text.toLowerCase().includes(searchTerm.toLowerCase());
                return (
                  <tr key={idx} style={isHighlighted ? { backgroundColor: 'yellow' } : {}}>
                    <td>
                      <CheckboxContainer>
                        <Checkbox
                          checked={checkedUtterances[idx] || false}
                          onChange={() => handleCheckboxChange(idx)}
                        />
                      </CheckboxContainer>
                    </td>
                    <td>{formatNumber(utterance.start_time)}</td>
                    <td>{formatNumber(utterance.end_time)}</td>
                    <td>{isHighlighted ? highlightText(utterance.text, searchTerm) : utterance.text}</td>
                    {utterance.speaker && <td>{utterance.speaker}</td>}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        ) : (
          <p>No transcription available for this asset.</p>
        )}
      </TabPanel>
    </>
  );
};

function formatValue(value: any): string {
  if (value === null || value === undefined) {
    return '';
  } else if (typeof value === 'object') {
    if (Array.isArray(value)) {
      return value.map(formatValue).join(', ');
    } else {
      return JSON.stringify(value);
    }
  } else {
    return String(value);
  }
}

function formatNumber(num: number): string {
  return num.toFixed(2);
}

function TabPanel(props: { children?: React.ReactNode; index: number; value: number }) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

export default VideoCard;
