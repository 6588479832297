import DescriptionIcon from '@mui/icons-material/Description';
import GroupIcon from '@mui/icons-material/Group';
import SubjectIcon from '@mui/icons-material/Subject';
import {
  Button,
  Card,
  CardContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { getAuth } from 'firebase/auth';
import React, { useCallback, useState } from 'react';
import { API_URL } from 'src/utils/api';
import styled from 'styled-components';

const FormContainer = styled.div`
  max-width: 1000px;
  margin: 0 auto;
`;

const StyledCard = styled(Card)`
  margin-bottom: 16px;
  transition: box-shadow 0.3s;

  &:hover {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  }
`;

const CardTitle = styled(Typography)`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  font-weight: bold;
`;

const IconWrapper = styled.span`
  margin-right: 8px;
  display: flex;
  align-items: center;
`;

const StyledTextField = styled(TextField)`
  width: 100%;
  margin-bottom: 16px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
`;

const StyledButton = styled(Button)`
  margin-left: 8px;
`;

interface TopicFormData {
  topic: string;
  description: string;
  audience: string;
}

interface TopicFormProps {
  onSubmit: (form: TopicFormData) => void | Promise<void>;
}

const TopicForm: React.FC<TopicFormProps> = ({ onSubmit }) => {
  const [formData, setFormData] = useState<TopicFormData>({
    topic: '',
    description: '',
    audience: '',
  });

  const handleChange = (field: keyof typeof formData, value: string) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    onSubmit(formData);
  };

  return (
    <FormContainer>
      <form onSubmit={handleSubmit}>
        <StyledCard>
          <CardContent>
            <CardTitle variant="h6">
              <IconWrapper>
                <SubjectIcon />
              </IconWrapper>
              Topic
            </CardTitle>
            <StyledTextField
              label="Topic"
              value={formData.topic}
              onChange={(e) => handleChange('topic', e.target.value)}
              variant="outlined"
              placeholder="Education"
              required
            />
          </CardContent>
        </StyledCard>

        <StyledCard>
          <CardContent>
            <CardTitle variant="h6">
              <IconWrapper>
                <DescriptionIcon />
              </IconWrapper>
              Description
            </CardTitle>
            <StyledTextField
              label="Description"
              value={formData.description}
              onChange={(e) => handleChange('description', e.target.value)}
              variant="outlined"
              multiline
              placeholder="Benefits of higher education"
              rows={4}
              required
            />
          </CardContent>
        </StyledCard>

        <StyledCard>
          <CardContent>
            <CardTitle variant="h6">
              <IconWrapper>
                <GroupIcon />
              </IconWrapper>
              Audience
            </CardTitle>
            <StyledTextField
              label="Audience"
              value={formData.audience}
              onChange={(e) => handleChange('audience', e.target.value)}
              variant="outlined"
              placeholder="middle-aged voters"
              required
            />
          </CardContent>
        </StyledCard>

        <ButtonContainer>
          <StyledButton
            variant="contained"
            color="secondary"
            onClick={() => setFormData({ topic: '', description: '', audience: '' })}
          >
            Clear
          </StyledButton>
          <StyledButton variant="contained" color="primary" type="submit">
            Submit
          </StyledButton>
        </ButtonContainer>
      </form>
    </FormContainer>
  );
};

interface ScriptGenApiResponse {
  messages: { message: string; audience: string; topic: string }[];
}

const ScriptGen: React.FC = () => {
  const [result, setResult] = useState<ScriptGenApiResponse | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const onSubmit = useCallback(async (formData: TopicFormData) => {
    setLoading(true);
    setError(null);

    try {
      const auth = getAuth();
      const user = auth.currentUser;
      if (!user) {
        throw new Error('No user logged in');
      }

      const idToken: string = await user.getIdToken();

      const response = await fetch(`${API_URL}/generate_script`, {
        method: 'POST',
        body: JSON.stringify(formData),
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
      });

      if (!response.ok) {
        console.error('Request failed', response);
        throw new Error('Request failed');
      }

      const body = await response.json();
      setLoading(false);
      setError(null);
      setResult(body);
    } catch (e) {
      console.error('Error generating script', e);
      setLoading(false);
      setError(e instanceof Error ? e : new Error('Unexpected error'));
    }
  }, []);

  return (
    <>
      <TopicForm onSubmit={onSubmit} />
      {loading ? (
        <p>Generating script... Please be patient this can take a minute or two.</p>
      ) : error ? (
        <p>Error generating script.</p>
      ) : (
        result && (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="messages">
              <TableHead>
                <TableRow>
                  <TableCell component="th">Message</TableCell>
                  <TableCell component="th">Audience</TableCell>
                  <TableCell component="th">Topic</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {result.messages.map(({ message, audience, topic }) => (
                  <TableRow>
                    <TableCell>{message}</TableCell>
                    <TableCell>{audience}</TableCell>
                    <TableCell>{topic}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )
      )}
    </>
  );
};

export default ScriptGen;
