import React, { useEffect, useState, useMemo, useCallback } from 'react';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  setDoc,
  Timestamp,
  updateDoc,
  where,
} from 'firebase/firestore';
import { nanoid } from 'nanoid';
import styled from 'styled-components';
import { Asset, EditingConfig, Overlay, Utterance } from 'shared-models';
import { API_URL } from 'src/utils/api';
import { auth, db, analytics } from '../firebase';
import { theme } from '../styles/theme';
import { Grid } from '../styles/VideoList.style';
import EditingDialog from './EditingDialog';
import Spinner from './Spinner';
import VideoCard from './VideoCard';
import { logEvent } from 'firebase/analytics';

const ASSETS_COLLECTION = `assets`;
const RATINGS_COLLECTION = `ratings`;
const FLAGGED_CONTENT_COLLECTION = `flagged_content`;
const EDIT_REQUESTS_COLLECTION = "edit_requests";
const HUMAN_ANNOTATIONS_COLLECTION = `human_annotations`;
const SCORES_COLLECTION = `scores-prod`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

const PageButton = styled.button<{ $active?: boolean }>`
  margin: 0 5px;
  padding: 5px 10px;
  background-color: ${(props) =>
    props.$active ? theme.colors.secondary : theme.colors.primary};
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const PageInfo = styled.span`
  margin: 0 10px;
`;

interface VideoListProps {
  assetIds: { id: string }[];
  filterType: string;
  filterValue: string;
}

export interface ScoreData {
  date_scored: number;
  percentiles: Record<string, number>
  scores: Record<string, number>;
}

const filterAssets = (assets: Asset[], filterType: string, filterValue: string) => {
  if (filterType === 'none' || !filterValue) return assets;

  return assets.filter((asset) => {
    const lowerFilterValue = filterValue.toLowerCase();
    switch (filterType) {
      case 'search':
        const description = asset.annotations?.description?.toLowerCase() || '';
        const transcript =
          asset.transcription?.map((u) => u.text.toLowerCase()).join(' ') || '';
        return description.includes(lowerFilterValue) || transcript.includes(lowerFilterValue);
      case 'machine_topics':
        return asset.annotations?.topics?.some((topic) =>
          topic.toLowerCase().includes(lowerFilterValue),
        );
      case 'machine_speakers':
        return Object.values(asset.annotations?.speakers ?? {}).some((speaker) =>
          speaker.toLowerCase().includes(lowerFilterValue),
        );
      case 'machine_vibes':
        return asset.annotations?.vibes?.some((vibe) =>
          vibe.toLowerCase().includes(lowerFilterValue),
        );
      case HUMAN_ANNOTATIONS_COLLECTION:
        return Object.entries(asset.human_annotations || {}).some(
          ([key, value]) =>
            key.toLowerCase().includes(lowerFilterValue) ||
            String(value).toLowerCase().includes(lowerFilterValue),
        );
      default:
        return true;
    }
  });
};

export type CheckedUtterancesType = boolean[];
export type AssetEditorState = {
  id: string;
  checkedUtterances: CheckedUtterancesType;
};

function* batch<T>(items: T[], n: number) {
  for (let start = 0; start < items.length; start += n) {
    yield items.slice(start, start + n);
  }
}

async function fetchAsset(id: string): Promise<Asset | null> {
  try {
    const docRef = doc(db, ASSETS_COLLECTION, id);
    const docSnap = await getDoc(docRef);
    if (!docSnap.exists()) {
      console.warn(`Asset with ID ${id} does not exist.`);
      return null;
    }

    const humanAnnotationsDoc = await getDoc(doc(db, HUMAN_ANNOTATIONS_COLLECTION, id));
    const { preview, ...human_annotations } = humanAnnotationsDoc.data() ?? {};

    return { ...(docSnap.data() as Asset), human_annotations };
  } catch (error) {
    console.error(`Error fetching asset with ID ${id}:`, error);
    return null;
  }
}

const FIRESTORE_IN_OPERATOR_LIMIT = 30;

const VideoList: React.FC<VideoListProps> = ({ assetIds, filterType, filterValue }) => {
  const [assetEditorState, setAssetEditorState] = useState<AssetEditorState | null>(null);
  const [overlays, setOverlays] = useState<Overlay[]>([]);
  const [assetRatings, setAssetRatings] = useState<{ [key: string]: number }>({});
  const [userRatings, setUserRatings] = useState<{ [key: string]: number }>({});
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 4;
  const [assets, setAssets] = useState<Record<string, Asset>>({});
  const [scores, setScores] = useState<Record<string, ScoreData>>({});
  const [loading, setLoading] = useState(true);

  const fetchScoresForAssets = useCallback(async (assetIds: string[]) => {
    const newScores: Record<string, ScoreData> = {};

    for (const batchIds of batch(assetIds, FIRESTORE_IN_OPERATOR_LIMIT)) {
      try {
        const scoresQuery = query(
          collection(db, SCORES_COLLECTION),
          where('__name__', 'in', batchIds),
        );
        const snapshot = await getDocs(scoresQuery);

        snapshot.docs.forEach((doc) => {
          const data = doc.data() as ScoreData;
          if (data.scores) {
            newScores[doc.id] = data;
            console.log(`Scores found for asset ${doc.id}:`, data);
          } else {
            console.log(`No scores found for asset ${doc.id}`);
          }
        });
      } catch (error) {
        console.error('Error fetching scores for batch:', error);
        // Optionally, implement retry logic here
      }
    }

    setScores((prevScores) => ({ ...prevScores, ...newScores }));
  }, []);

  useEffect(() => {
    let ignore = false;
    setLoading(true);

    async function fetchData() {
      const assetPromises = assetIds.map(({ id }) => fetchAsset(id));
      const fetchedAssets = await Promise.all(assetPromises);
      if (!ignore) {
        const validAssets = fetchedAssets.filter((asset): asset is Asset => asset !== null);
        const newAssets = Object.fromEntries(validAssets.map((asset) => [asset.id, asset]));
        setAssets(newAssets);

        await fetchScoresForAssets(Object.keys(newAssets));
        setLoading(false);
      }
    }

    fetchData();

    return () => {
      ignore = true;
    };
  }, [assetIds, fetchScoresForAssets]);

  const filteredAssets = useMemo(() => {
    return filterAssets(Object.values(assets), filterType, filterValue);
  }, [assets, filterType, filterValue]);

  const totalPages = Math.ceil(filteredAssets.length / itemsPerPage);

  const currentItems = useMemo(() => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    return filteredAssets.slice(indexOfFirstItem, indexOfLastItem);
  }, [filteredAssets, currentPage]);

  const changePage = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const renderPagination = () => {
    if (totalPages <= 1) return null;

    const pageNumbers = [];
    const maxVisiblePages = 5;

    let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
    let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

    if (endPage - startPage + 1 < maxVisiblePages) {
      startPage = Math.max(1, endPage - maxVisiblePages + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return (
      <PaginationContainer>
        <PageButton onClick={() => changePage(currentPage - 1)} disabled={currentPage === 1}>
          &lt;
        </PageButton>
        {pageNumbers.map((number) => (
          <PageButton
            key={number}
            onClick={() => changePage(number)}
            $active={number === currentPage}
          >
            {number}
          </PageButton>
        ))}
        <PageButton
          onClick={() => changePage(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          &gt;
        </PageButton>
        <PageInfo>
          Page {currentPage} of {totalPages}
        </PageInfo>
      </PaginationContainer>
    );
  };

  useEffect(() => {
    const fetchOverlays = async () => {
      try {
        const user = auth.currentUser;
        if (!user) {
          throw new Error('User not authenticated');
        }

        const idToken = await user.getIdToken();
        const response = await fetch(`${API_URL}/overlays`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch overlays');
        }
        const data = await response.json();
        setOverlays(data);
      } catch (error) {
        console.error('Error fetching overlays:', error);
      }
    };

    fetchOverlays();
  }, []);

  useEffect(() => {
    let unsubscribe: (() => void) | undefined;

    const fetchRatings = () => {
      const user = auth.currentUser;
      if (!user) return;

      const ratingsQuery = query(collection(db, RATINGS_COLLECTION));
      unsubscribe = onSnapshot(ratingsQuery, (snapshot) => {
        const newAssetRatings: { [key: string]: number } = {};
        const newUserRatings: { [key: string]: number } = {};

        snapshot.docs.forEach((doc) => {
          const data = doc.data();
          if (data.assetId && data.rating) {
            newAssetRatings[data.assetId] = (newAssetRatings[data.assetId] || 0) + data.rating;
            if (data.userId === user.uid) {
              newUserRatings[data.assetId] = data.rating;
            }
          }
        });

        setAssetRatings(newAssetRatings);
        setUserRatings(newUserRatings);
      });
    };

    fetchRatings();

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  const handleFlagContent = async (assetId: string) => {
    const user = auth.currentUser;
    if (!user) {
      alert('You must be logged in to flag content.');
      return;
    }

    try {
      await addDoc(collection(db, FLAGGED_CONTENT_COLLECTION), {
        assetId: assetId,
        userId: user.uid,
        flaggedAt: Timestamp.now(),
      });
      alert('Content has been flagged for review.');
    } catch (error) {
      console.error('Error flagging content:', error);
      alert('Failed to flag content. Please try again.');
    }
  };

  const handleSubmitClick = (state: AssetEditorState) => {
    setAssetEditorState(state);
  };

  const handleDialogClose = () => {
    setAssetEditorState(null);
  };

  const handleRating = async (assetId: string, ratingValue: 1 | -1) => {
    const user = auth.currentUser;
    if (!user) {
      alert('You must be logged in to rate content.');
      return;
    }

    try {
      const ratingRef = doc(db, RATINGS_COLLECTION, `${user.uid}_${assetId}`);
      const ratingSnap = await getDoc(ratingRef);

      if (ratingSnap.exists()) {
        const currentRating = ratingSnap.data().rating;
        if (currentRating === ratingValue) {
          // If already rated the same, remove the rating
          await deleteDoc(ratingRef);
        } else {
          // Update to new rating
          await updateDoc(ratingRef, { rating: ratingValue });
        }
      } else {
        // Create new rating
        await setDoc(ratingRef, {
          assetId,
          userId: user.uid,
          rating: ratingValue,
          timestamp: Timestamp.now(),
        });
      }
    } catch (error) {
      console.error('Error updating rating:', error);
      alert('Failed to update rating. Please try again.');
    }
  };

  const handleThumbsUp = (assetId: string) => handleRating(assetId, 1);
  const handleThumbsDown = (assetId: string) => handleRating(assetId, -1);

  const handleSubmitAsset = async (config: EditingConfig) => {
  if (assetEditorState === null) return;

  const { id: currentAssetId, checkedUtterances } = assetEditorState;

  const asset = assets[currentAssetId];
  if (!asset) {
    console.error('Asset not found');
    handleDialogClose();
    return;
  }

  if (!asset.transcription) {
    console.error('No transcription available for this asset');
    handleDialogClose();
    return;
  }

  const transcription = asset.transcription; // Capture the transcription in a local variable

  const selectedUtterances = (checkedUtterances || [])
    .map((checked, index) =>
      checked && index < transcription.length ? transcription[index] : null,
    )
    .filter((utterance): utterance is Utterance => utterance !== null);

  const user = auth.currentUser;
  if (!user) {
    alert('You must be logged in to submit an asset.');
    handleDialogClose();
    return;
  }

  try {
    const idToken = await user.getIdToken();
    const editRequestId = nanoid(21);

    const data = {
      id: editRequestId,
      asset_id: asset.id,
      selected_utterances: selectedUtterances,
      editing_config: config,
    };

    const editRequestData = {
      id: editRequestId,
      assetId: asset.id,
      userId: user.uid,
      status: 'pending',
      createdAt: Timestamp.now(),
      selectedUtterances: selectedUtterances,
      editingConfig: config,
      read: false,
    };

    const editRequestRef = doc(db, EDIT_REQUESTS_COLLECTION, editRequestId);
    await setDoc(editRequestRef, editRequestData);
    console.log("Document written with ID: ", editRequestRef.id);

    const response = await fetch(`${API_URL}/edit_video`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${idToken}`,
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error('Failed to submit video editing job');
    }

    await updateDoc(editRequestRef, {"status": "submitted"});
    console.log('Submitting edit request to firestore:', editRequestData)

    const result = await response.json();
    console.log('Job submitted successfully:', result);

    console.log('Edit request created with Document ID: ', editRequestId);
    logEvent(analytics, 'video_edit_request', {
      asset_id: asset.id,
      edit_request_id: editRequestId,
      utterance_count: selectedUtterances.length,
      config: JSON.stringify(config),
      user_id: user.uid,
    });
    alert('Video editing job submitted. You will be notified when it is complete.');
  } catch (error) {
    console.error('Error submitting video editing job:', error);
    logEvent(analytics, 'video_edit_request_error', {
      asset_id: asset.id,
      error_message: error instanceof Error ? error.message : String(error),
    });
    alert('Failed to submit video editing job. Please try again.');
  }
  handleDialogClose();
};

  if (loading) {
    return <Spinner />;
  }

  if (filteredAssets.length === 0) {
    return <div>No assets found.</div>;
  }

  return (
    <>
      <Grid>
        {currentItems.map((asset) => (
          <VideoCard
            key={asset.id}
            asset={asset}
            scoreData={scores[asset.id] || null}
            assetRating={assetRatings[asset.id]}
            userRating={userRatings[asset.id]}
            handleFlagContent={handleFlagContent}
            handleSubmitClick={handleSubmitClick}
            handleThumbsUp={handleThumbsUp}
            handleThumbsDown={handleThumbsDown}
            searchTerm={filterType === 'search' ? filterValue : ''}
          />
        ))}
      </Grid>

      <EditingDialog
        open={assetEditorState != null}
        onClose={handleDialogClose}
        onSubmit={handleSubmitAsset}
        overlays={overlays}
      />

      {filteredAssets.length > 0 && renderPagination()}
    </>
  );
};

export default VideoList;

